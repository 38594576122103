import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { ProductModel } from './interfaces/generated';
import { is } from './typechecker'
import Axios from 'axios';
import { ApiResponseObjectError, ApiResponseObjectRoot } from './interfaces/ApiResults';
import { Vector3 } from 'three';

export class Viewer3D {
	private readonly scene: THREE.Scene;
	private readonly group: THREE.Group;
	private readonly camera: THREE.PerspectiveCamera;
	private readonly clock: THREE.Clock
	private readonly ambientLight: THREE.AmbientLight;
	private readonly pointLight: THREE.PointLight;
	private readonly axesHelper: THREE.AxesHelper;
	private readonly gridHelper: THREE.GridHelper;
	private readonly storage: Storage;
	private readonly renderer: THREE.WebGLRenderer;
	private readonly controls: OrbitControls;
	private readonly canvas: HTMLCanvasElement;

	private axesEnabled: boolean = false;
	private gridEnabled: boolean = false;
	private model?: THREE.Object3D;

	// for fps counter.
	private fps: number = 0;
	private times: number[] = [];

	private initializeObject: any = {};

	private warnings: string[] = [];
	private infos: string[] = [];
	private errors: ApiResponseObjectError[] = [];
	errorLogEnabled: boolean = false;
	displayFpsEnabled: boolean = false;

	private productsModel?: ProductModel[];
	private demoMode: boolean = false;

	private loading: boolean = false;

	constructor(canvas: HTMLCanvasElement, settingsStore: Storage) {
		this.canvas = canvas;
		this.storage = settingsStore;
		this.clock = new THREE.Clock();
		this.scene = new THREE.Scene();
		this.ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
		this.pointLight = new THREE.PointLight(0xffffff, 0.8);
		this.axesHelper = new THREE.AxesHelper(800);
		this.gridHelper = new THREE.GridHelper(800);
		this.group = new THREE.Group();
		this.camera = new THREE.PerspectiveCamera(25, canvas.clientWidth / canvas.clientHeight, 0.1, 11000);
		this.renderer = new THREE.WebGL1Renderer({ antialias: true, alpha: true, canvas: canvas });
		this.renderer.setSize(canvas.clientWidth, canvas.clientHeight);

		this.camera.add(this.pointLight);
		this.scene.add(this.camera, this.ambientLight);

		// Set orbital controls.
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.screenSpacePanning = true;
		this.controls.minDistance = 45;
		this.controls.maxDistance = 10000;
		this.controls.update();

		this.canvas.ownerDocument.defaultView?.addEventListener("resize", () => this.onWindowResize());

		this.restoreSettings();

		this.resetCamera();

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		let myBoolean = false;

		if (urlParams.get('localScreenshot') == "1") {
			myBoolean = true;
		}

		let fileName = urlParams.get('fileName')?.toString() ?? "test.png";

		this.animate(0, myBoolean, fileName);
	}

	public async reinitalize() {
		if (this.productsModel != null) {		
			await this.initialize(this.productsModel);
		}
	}

	public async initializeRawRemote(multidata: ViewerDataRemote[]): Promise<void> {
		multidata.forEach( (item) => {
			let data = item;
			this.initializeObject = item;	

			// @Antonie:  data.objectFormat     1=Obj   2=Gltf

			let objLoader = new OBJLoader();
			let mtlLoader = new MTLLoader();
			let gltfLoader = new GLTFLoader();

			let object_position = new Vector3();

			if (data.objectFormat == "Obj") {
				mtlLoader.load(data.mtlUrl, (materials) => {
					objLoader.setMaterials(materials);
					objLoader.load(data.objUrl, (res) => {
						this.load(res, data.offset)
							object_position = res.position;
					});
				});

				mtlLoader.loadAsync(data.mtlUrl)
					.then(materials => {
						objLoader.setMaterials(materials);
						return objLoader.loadAsync(data.objUrl)
					}).then(res => {
						let test = this.load(res, data.offset);
						object_position = res.position;
					})

				this.addInfo(data.product + " Rendered in " + data.objectFormat + "(position: " + object_position.x + " - offset: x=" + data.offset.x + ", y=" + data.offset.y + ", z=" + data.offset.z + ")");
				return this.scene.add(this.group);
			}
			else if (data.objectFormat == "Gltf") {
				gltfLoader.loadAsync(data.objUrl)
					.then(res => {
						this.load(res.scene, data.offset);
						res.scene.traverse((child: { material: { metalness: number; }; }) => {
							if (child.material) child.material.metalness = 0;

						});

						object_position = res.position;
					})


				this.addInfo(data.product + " Rendered in " + data.objectFormat + "(position: " + object_position.x + " - offset: x=" + data.offset.x + ", y=" + data.offset.y + ", z=" + data.offset.z + ")");

				

				return this.scene.add(this.group);
			}
		});

		// rotate to creoox system
		this.group.rotateX(1.5707963268);
		this.group.rotateZ(1.5707963268);
		this.group.rotateY(3.1415926536);
	}

	public initializeRaw(data: ViewerData) {
		this.clearWarningsAndErrors();
		this.initializeObject = data;
		let objLoader = new OBJLoader();
		let mtlLoader = new MTLLoader();
		// let gltfLoader = new GLTFLoader();
		
		var materialCreator = mtlLoader.parse(data.mtlData, "")
		objLoader.setMaterials(materialCreator)
		let obj = objLoader.parse(data.objData);

		this.load(obj, new THREE.Vector3(0, 0, 0));
	}

	public initialize(data: ProductModel[]): Promise<void> {
		this.clearWarningsAndErrors();
		this.productsModel = data;

		var generateSettings = {
			angularDeflection: this.getAngularDeflection(),
			linearDeflection: this.getLinearDeflection()
		}
		// console.log(generateSettings);
		this.loading = true;

		// Declare the result object
		let args: ViewerDataRemote[] = new Array();

		// Process the data by a post to our function which calls the Creoox Engine
		return Axios.post("/api/GenerateProductsModel", { products: data, generateSettings: generateSettings })
			.then(httpres => {
				let res: ApiResponseObjectRoot[] = httpres.data;

				res.forEach((element) => {
					if (element.data.warnings != null && element.data.warnings.length > 0) {
						element.data.warnings.forEach(warn => {
							warn = element.data.product + " - " + warn;
							this.addWarning(warn);
						});
					}

					if (element.data.errors != null && element.data.errors.length > 0) {
						element.data.errors.forEach(err => {
							err.hint = element.data.product + " - " + err.hint;
							this.addError(err);
						});

						console.error("Errors found while generating object. See previous errors for more detail.");
					}


					element.data.productPositions.forEach((productPosition) => {
						let myitem: ViewerDataRemote = {
							objectFormat: element.data.model.objectFormat,
							mtlUrl: element.data.model.mtlFile?.downloadUrl,
							objUrl: element.data.model.objFile.downloadUrl,
							offset: new THREE.Vector3(productPosition.offset.x, productPosition.offset.y, productPosition.offset.z),
							product: element.data.product
						}

						args.push(myitem);
					});
				});

				// Initialize the 3D Viewer itself
				return this.initializeRawRemote(args);
			}).catch(err => {
				var tmp = new ApiResponseObjectError();
				tmp.hint = "Error occured while generating 3D files.";
				tmp.message = err.message;
				this.addError(tmp)
			}).finally(() => {
				this.loading = false;
			})
	}

	public setDemoMode(enable: boolean) {
		this.demoMode = enable;
	}

	public getDemoMode(): boolean {
		return this.demoMode
	}

	public addError(err: ApiResponseObjectError) {
		this.errors.push(err);
	}

	public addWarning(warn: string) {
		this.warnings.push(warn);
	}

	public addInfo(info: string) {
		this.infos.push(info);
	}

	private clearWarningsAndErrors() {
		this.warnings.length = 0;
		this.errors.length = 0;
		this.infos.length = 0;
	}

	public getErrors() {
		return this.errors;
	}

	public getWarnings() {
		return this.warnings;
	}

	public getInfos() {
		return this.infos;
	}


	private async load(obj: THREE.Object3D, pos: THREE.Vector3) {
		/*
		if (this.model != null) {
			this.scene.remove(this.model);
		}
		*/
		this.model = obj;
		this.group.add(this.model);

		this.model.position.set(pos.x, pos.y, pos.z);
		this.resetCamera();
	}

	private render() {
		this.renderer.render(this.scene, this.camera);
	}

	private animate(frame: number, takeScreenshot: boolean, fileName: string) {
		requestAnimationFrame(() => {
			frame++;
			this.animate(frame,takeScreenshot, fileName)
			const now = performance.now();
			while (this.times.length > 0 && this.times[0] <= now - 1000) {
				this.times.shift();
			}
			this.times.push(now);
			this.fps = this.times.length;
		});
		
		this.render();

		if (frame == 500 && takeScreenshot) {
			let imgData = this.canvas.toDataURL("image/png");
			console.log(imgData);
			this.saveFile(imgData.replace("image/png", "image/octet-stream"), fileName);
		}
	}

	private setStorageItemBool(key: string, value: boolean) {
		this.storage.setItem(key, value ? "on" : "off");
	}

	private setStorageItemInt(key: string, value: number) {
		this.storage.setItem(key, value.toString());
	}

	private getStorageItemBool(key: string): boolean {
		return this.storage.getItem(key) == "on";
	}

	private getStorageItemInt(key: string): number {
		var storageVal = this.storage.getItem(key);
		var val = parseFloat(storageVal ?? "-1");
		if (!isNaN(val)) {
			return val;
		}

		return -1;
	}

	public resetCamera() {
		this.camera.position.set(791, 258, 330);

		if (this.model != null) {
			this.fitCameraToSelection(this.group)
		}

		this.controls.update();
	}

	public isLoading(): boolean {
		return this.loading;
	}

	private fitCameraToSelection(...objects: THREE.Object3D[]) {
		let fitOffset = 1.5;
		const box = new THREE.Box3();

		for (const object of objects) box.expandByObject(object);

		const size = box.getSize(new THREE.Vector3());
		const center = box.getCenter(new THREE.Vector3());

		const maxSize = Math.max(size.x, size.y, size.z);
		const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * this.camera.fov / 360));
		const fitWidthDistance = fitHeightDistance / this.camera.aspect;
		const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

		const direction = this.controls.target.clone()
			.sub(this.camera.position)
			.normalize()
			.multiplyScalar(distance);

		this.controls.maxDistance = distance * 10;
		this.controls.target.copy(center);

		this.camera.near = distance / 100;
		this.camera.far = distance * 100;
		this.camera.updateProjectionMatrix();
		this.camera.position.copy(this.controls.target).sub(direction);
		this.controls.update();
	}

	private onWindowResize() {
		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();

		this.renderer.setSize(window.innerWidth, window.innerHeight);
	}

	public toggleAxes(state?: boolean): boolean {
		if (state != null) {
			// Set the axesEnabled to the inverse of state so it will toggle to the desired result.
			this.axesEnabled = !state;
		}

		// Check if axesEnabled state and act accordingly
		if (!this.axesEnabled) {
			this.scene.add(this.axesHelper);
		}
		else {
			this.scene.remove(this.axesHelper);
		}

		// Invert boolean and return value.
		this.axesEnabled = !this.axesEnabled;

		this.setStorageItemBool("axes", this.axesEnabled);
		return this.axesEnabled;
	}

	public getAxesEnabled(): boolean {
		return this.axesEnabled;
	}

	public toggleGrid(state?: boolean): boolean {
		if (state != null) {
			// Set the gridEnabled to the inverse of state so it will toggle to the desired result.
			this.gridEnabled = !state;
		}

		// Check if gridEnabled state and act accordingly
		if (!this.gridEnabled) {
			this.scene.add(this.gridHelper);
		}
		else {
			this.scene.remove(this.gridHelper);
		}

		// Invert boolean and return value.
		this.gridEnabled = !this.gridEnabled;

		this.setStorageItemBool("grid", this.gridEnabled);
		return this.gridEnabled;
	}

	public saveFile = (strData: string, filename: string): void => {
		const link = document.createElement('a');
		if (typeof link.download === 'string') {
			document.body.appendChild(link); // Firefox requires the link to be in the body
			link.download = filename;
			link.href = strData;
			link.click();
			document.body.removeChild(link); // remove the link when done
		} else {
			//location.replace(uri);
		}
	}



	public toggleErrorLog(state?: boolean): boolean {
		if (state != null) {
			// Set the value to the inverse of state so it will toggle to the desired result at the end of this function.
			this.errorLogEnabled = !state;
		}

		this.errorLogEnabled = !this.errorLogEnabled;
		this.setStorageItemBool("errorlogenabled", this.errorLogEnabled);

		return this.errorLogEnabled;
	}

	public getGridEnabled(): boolean {
		return this.gridEnabled;
	}

	public getErrorLogEnabled(): boolean {
		return this.errorLogEnabled;
	}

	public getCurrentFps(): number {
		return this.fps;
	}

	public getDisplayFpsEnabled(): boolean {
		return this.displayFpsEnabled;
	}

	public toggleDisplayFpsEnabled(state?: boolean): boolean {
		if (state != null) {
			// Set the value to the inverse of state so it will toggle to the desired result at the end of this function.
			this.displayFpsEnabled = !state;
		}

		this.displayFpsEnabled = !this.displayFpsEnabled;
		this.setStorageItemBool("displayfpsenabled", this.displayFpsEnabled);

		return this.displayFpsEnabled;
	}

	public getDebugInfo(): object {
		return {
			data: this.initializeObject
		}
	}

	public setLinearDeflection(val: number) {
		return this.setStorageItemInt("lineardeflection", val);
	}

	public getLinearDeflection() {
		return this.getStorageItemInt("lineardeflection") ?? 0.1;
	}

	public setAngularDeflection(val: number) {
		this.setStorageItemInt("angulardeflection", val);
	}

	public getAngularDeflection() {
		return this.getStorageItemInt("angulardeflection") ?? 0.1;
	}

	private restoreSettings() {
		this.toggleGrid(this.getStorageItemBool("grid"));
		this.toggleAxes(this.getStorageItemBool("axes"));
		this.toggleErrorLog(this.getStorageItemBool("errorlogenabled"));
		this.toggleDisplayFpsEnabled(this.getStorageItemBool("displayfpsenabled"));

		let ad = this.getAngularDeflection();
		if (ad < 0 || ad > 1) {
			this.setAngularDeflection(0);
		}

		let lf = this.getLinearDeflection();
		if (lf < 0 || ad > 1) {
			this.setLinearDeflection(0);
		}

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		let myBoolean = false;
		if (urlParams.get('localScreenshot') == "1") {
			this.toggleGrid(false);
			this.toggleAxes(false);
			this.toggleErrorLog(false);
			this.toggleDisplayFpsEnabled(false);
		}
	}

	private emitSettingChanged(setting: string) {
		var event = new CustomEvent("settingchange", { detail: setting });
	}
}
